body {
    overflow-x: hidden;
}

html {
    overflow-x: hidden;
    font-size: 100px !important;
    -webkit-overflow-scrolling: touch;
}

p {
    margin-bottom: 0;
}

.topbg {
    width: 100%;
}

.top {
    height: 2.25rem;
    width: 100%;
    padding: .15rem;
    background: no-repeat url('./../../assets/imgMobile/top_bg@2x.png') top/100%;
}

.logo {
    width: .75rem;
}

.topRight {
    float: right;
    width: .17rem;
}

.banner {
    width: 100%;
    height: 1.6rem;
    background: no-repeat url('./../../assets//imgMobile/banner_bg@2x.png');
    background-size: 100% 100%;
}

.banner-p {
    font-size: .18rem;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    line-height: .29rem;
    margin-left: .2rem;
}

.btn-l-20 {
    margin-left: .2rem;
    margin-top: .23rem;
    font-size: .12rem;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: rgba(68, 68, 68, 1);
    line-height: .07rem;
}

.mt30 {
    padding-top: .3rem;
}

.homeTitle {
    font-size: .18rem;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: .18rem;
}

.h2_cn {
    font-size: .17rem;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);
    line-height: .16rem;
    text-align: center;
    margin-bottom: .08rem;
}

.h2_en {
    font-size: .1rem;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: .18rem;
    text-align: center;
    margin-bottom: .21rem;
}

.product_name {
    font-size: .15rem;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: rgba(33, 110, 244, 1);
    line-height: .12rem;
    text-align: center;
    margin-bottom: .2rem;
}

.proDetail {
    width: calc(100% - .5rem);
    height: auto;
    margin-left: .25rem;
}

.showMore {
    font-size: 12px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: rgba(33, 110, 244, 1);
    margin: .25rem auto;
    display: inherit;
}

.cooperate {
    width: 100vw;
    height: 2.2rem;
    background: no-repeat url('./../../assets/imgMobile/xietong_bg@2x.png') 100%;
    /* background-size: 100% 100%; */
    padding-top: .25rem;
}

.cooperate .p1 {
    font-size: 17px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    line-height: 16px;
    text-align: center
}

.cooperate .p2 {
    font-size: 10px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 9px;
    opacity: 0.6;
    margin-top: .08rem;
    text-align: center;
}

.cooperate button {
    font-size: 13px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    margin-top: 0;
}

.button-home {
    text-align: center;
    margin-top: .18rem;
}

.color_white {
    color: #fff;
}

.swiper-box {
    min-width: 100vw;
    height: .72rem;
    padding-left: .15rem;
    margin-top: .2rem;
    display: -webkit-box;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
}

.swiper-box div {
    width: .72rem;
    height: .72rem;
    background: rgba(255, 255, 255, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.swiper-box div p {
    font-size: .12rem;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: .12rem;
    margin-top: .1rem;
}

.swiper-box-img {
    width: .26rem;
    height: .23rem;
}

.narrow {
    width: .15rem;
    height: .03rem;
    display: inherit;
    margin-top: .6rem;
    margin-left: .05rem;
    margin-right: .05rem;
}

.xietong {
    padding-top: .24rem;
}

.xietong .p1 {
    font-size: 17px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);
    line-height: .16rem;
    text-align: center
}

.xietong .p2 {
    font-size: 10px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: .09rem;
    opacity: 0.6;
    margin-top: .08rem;
    text-align: center;
}

.pl16 {
    padding-left: .16rem;
    padding-right: .16rem;
}

.mt20 {
    margin-top: .2rem;
}

.mt10 {
    margin-top: .1rem;
}

.xitong_div {
    padding-top: .25rem;
    padding-bottom: .21rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 2px 0px rgba(238, 238, 238, 0.96);
}

.tedian_icon {
    width: .38rem;
    height: auto;
}

.line_icon {
    margin-top: .2rem;
    margin-bottom: .2rem;
    width: .14rem;
    height: 1px;
    background-color: rgba(238, 238, 238, 1);
}

.tedian_title {
    font-size: 14px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: .12rem;
}

/* 优势 */

.gift {
    height: 7.6rem;
    width: 100%;
    background: url('./../../assets/imgMobile/youshi_bg@2x.png');
    background-size: 100% cover;
    padding-top: .5rem;
}

.gift .p1 {
    font-size: 17px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);
    line-height: .16rem;
    text-align: center
}

.gift .p2 {
    font-size: .1rem;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: .09rem;
    opacity: 0.6;
    margin-top: .08rem;
    text-align: center;
}

.gift_item {
    background-color: rgba(255, 255, 255, 1);
    margin-bottom: .1rem;
    padding: .17rem .15rem;
    display: flex;
    flex-direction: row;
    /* justify-content: ; */
}

.gift_item img {
    width: .6rem;
    height: .6rem;
}

.gift_item_div2 {
    margin-left: .15rem;
}

.gift_item_title {
    font-size: .15rem;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: .17rem;
}

.gift_item_content {
    font-size: 12px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: .2rem;
}

/* 客户 */

.client {
    padding: .22rem .13rem;
}

.client .p1 {
    font-size: 17px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);
    line-height: .16rem;
    text-align: center
}

.client .p2 {
    font-size: 10px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: .09rem;
    opacity: 0.6;
    margin-top: .08rem;
    text-align: center;
}

.client_swiper {
    min-width: 100vw;
    height: 1.45rem;
    padding-left: .15rem;
    margin-top: .2rem;
    display: -webkit-box;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
}

.client_swiper_item {
    border-bottom: 1px solid #e1e1e1;
    width: 1.56rem;
    margin-right: .1rem;
    overflow: hidden;
}

.client_swiper_item_p1 {
    font-size: 13px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* line-height: .08rem; */
    margin-top: .08rem;
    margin-bottom: .08rem;
}

.client_swiper_item_p2 {
    font-size: 11px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(49, 113, 255, 1);
    line-height: .07rem;
}

.client_swiper_item img {
    width: 100%;
}

/* 伙伴 */

.partner {
    padding: .22rem .22rem;
}

.partner .p1 {
    font-size: 17px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);
    line-height: .16rem;
    text-align: center
}

.partner .p2 {
    font-size: 10px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: .09rem;
    opacity: 0.6;
    margin-top: .08rem;
    text-align: center;
}

.partner img {
    height: .22rem;
    width: auto;
}

/* footer */

.footer {
    width: 100%;
    height: 1.85rem;
    background: rgba(33, 34, 48, 1);
    padding: .2rem;
    color: rgba(130, 135, 153, 1);
    font-size: .1rem;
}

.footer_img {
    width: .13rem;
    height: .1rem;
    margin-right: .08rem;
    /* margin-top: .12rem; */
    vertical-align: middle;
}

.footer_logo {
    height: .16rem;
    display: inline-block;
}

.footer_img_wechat {
    width: 1rem;
    height: 1rem;
    margin-top: .2rem;
}

.footer_img_wechat_title {
    font-size: 10px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: rgba(130, 135, 153, 1);
    line-height: .18rem;
    margin-top: .1rem;
}

.copyright {
    font-size: 10px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
    line-height: .18rem;
    border-top: 1px solid rgba(255, 255, 255, .1);
    text-align: center;
    /* position: absolute; */
    /* bottom: 0; */
    /* display: inline-block; */
    background-color: rgba(33, 34, 48, 1);
    padding: 8px;
}

.empty_box {
    display: inline-block;
    width: .13rem;
    height: .1rem;
    margin-right: .08rem;
    margin-top: .12rem;
}

.mt5 {
    margin-top: .05rem
}

.default_color {
    color: #fff;
}