#pcPage{color: #333;}
.content{width: 1200px;margin: auto;}
.pageTitle{text-align: center;margin:auto;margin-top: 60px;margin-bottom: 20px;width:268px;height:32px;font-size:32px;font-family:'PingFangSC-Regular','MicrosoftYaHeiUI';color:rgba(51,51,51,1);line-height:32px;letter-spacing:2px;}
.pageTitle2{text-align: center;margin: auto;width:308px;height:18px;font-size:18px;font-family:PingFangSC-Regular;color:rgba(102,102,102,1);line-height:18px;}

.sameTitle{margin-right: 18px;width:48px;height:24px;font-size:24px;font-family:'PingFangSC-Regular','MicrosoftYaHeiUI';color:rgba(51,51,51,1);line-height:24px;}
.sameTitle2{width:65px;height:16px;font-size:16px;font-family:PingFangSC-Regular;font-weight:400;color:rgba(102,102,102,1);line-height:16px;}
/*header区域*/
#header{height: 630px;background-image:url(../../assets/img/bg.png);background-size: 100% 100%;overflow: hidden;}
/*nav区域*/
#nav{height: 70px;border-bottom: 1px solid rgba(255,255,255,0.1);color: white;font-size: 14px;font-family:'PingFangSC-Light','MicrosoftYaHeiUILight';}
#nav .logo{width: 130px;height: 30px;background-image:url(../../assets/img/logo.png);background-size: 100% 100%;float: left;margin-top: 18px;}
#nav .menu{float: left;list-style: none;padding-top: 22px;}
#nav .menu li{float: left;margin-right: 40px;cursor: pointer;line-height: 25px;}
#nav .menu li.lastli{background:linear-gradient(90deg,rgba(55,111,250,1) 0%,rgba(153,81,180,1) 100%);width: 86px;height: 26px;border-radius: 13px;text-align: center;}
/* banner */
.bannerPc{color: white;}

/* product产品区域 */
#product{height: 900px;overflow: hidden;}
#product .features>div{cursor: pointer;}
#product .features>div:hover p{color: #216EF4;}
/* 协同过程 */
#process{height: 647px;background-image:url(../../assets/img/bg_2.png);background-size: 100% 100%;overflow: hidden;color: white;}
.processImg{width: 30px;height: 30px;}
.projiantou{width: 80px;float: left;background-image:url(../../assets/img/jiantou_3.png);height: 64px;background-repeat: no-repeat;background-position: bottom;}
/* 系统特点 */
#characteristic{height: 620px;overflow: hidden;}
#characteristic .chara{margin-top: 50px;}
#characteristic .charaTab{width: 240px;height: 320px;float: left;text-align: center;}
#characteristic .charaTab section{width: 100%;height: 100%;}
#characteristic .charaTab section img{margin-top: 93px;margin-bottom: 62px;}
#characteristic .charaTab section p{font-size:18px;font-family:'PingFangSC-Regular','MicrosoftYaHeiUI';color:rgba(51,51,51,1);line-height:18px;}
#characteristic .charaTab div{background: #3171FF;width: 100%;height: 100%;color: white;display: none;}
#characteristic .charaTab div img{margin-top: 40px;margin-bottom: 20px;}
#characteristic .charaTab div p{font-size:18px;font-family:'PingFangSC-Regular','MicrosoftYaHeiUI';line-height:18px;}
#characteristic .charaTab div span{margin-top: 26px;width:188px;display: inline-block;height:72px;font-size:12px;font-family:PingFangSC-Regular;font-weight:400;line-height:24px;}
#characteristic .charaTab:hover section{display: none;}
#characteristic .charaTab:hover div{display: block;}
/* 优势区域 */
#advantage{height: 718px;overflow: hidden;}
#advantage .advTitle{height: 240px;background-image: url(../../assets/img/bg-news.png);background-size: 100% 100%;overflow: hidden;}
#advantage .advContent{height: 478px;overflow: hidden;background:rgba(245,248,255,1);}
#advantage .advContent .advConTab{width: 380px;height: 184px;float: left;margin-bottom: 30px;background: white;padding: 30px;}
#advantage .advContent .advConTab div{overflow: hidden;}
#advantage .advContent .advConTab div img{float: left;}
#advantage .advContent .advConTab div span{float: right;font-size:20px;font-family:'PingFangSC-Regular','MicrosoftYaHeiUI';color:rgba(51,51,51,1);line-height:20px;}
#advantage .advContent .advConTab>span{display: inline-block;margin-top: 32px;width:322px;height:56px;font-size:14px;font-family:'PingFangSC-Regular','MicrosoftYaHeiUI';color:rgba(102,102,102,1);}
/* 客户 */
#client{overflow: hidden;}
#client .clientTab{margin-top: 40px;}
#client .clientTab>div{float: left;width: 380px;cursor: pointer;}
#client .clientTab>div p{font-size:16px;font-family:'PingFangSC-Regular','MicrosoftYaHeiUI';color:rgba(51,51,51,1);line-height:16px;margin-top: 30px;}
#client .clientTab>div span{font-size:14px;font-family:'PingFangSC-Regular','MicrosoftYaHeiUI';color:rgba(102,102,102,1);line-height:14px;}
/* #client .clientTab>div:hover p{color: #216EF4;} */
#client .clientTab>div hr{width: 360px;margin: 0px;height: 1px;background: #E1E1E1;border: none;margin-top: 30px;display: none;}
/* #client .clientTab>div:hover hr{display: block;} */
/* 合作伙伴 */
#partner{overflow: hidden;}
#partner img{margin: 0px 8.1%;}
/* 底部footer */
#footer{height: 330px;background:rgba(33,34,48,1);color:rgba(255,255,255,0.3);overflow: hidden;font-size:14px;font-family:PingFangSC-Regular;}